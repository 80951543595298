import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';
import { UtilitiesService } from '@athlete-x/shared';
import { Industry, TeamFiltersFills, UsersTypesLables, UserType } from '@athlete-x/definitions';
import { SportsStore, TeamStore } from '@athlete-x/stores';

const LOCATIONS: Array<string> = [
    'Baltimore',
    'Philadelphia',
    'New York',
    'San Francisco'
];

@Injectable({
    providedIn: 'root'
})
export class FilterHelpersService {
    private _http = inject(HttpClient);
    private _utilities = inject(UtilitiesService);
    private _teamStore = inject(TeamStore);
    private _sportsStore = inject(SportsStore);

    private _isGlobal$ = new BehaviorSubject(false);
    public isGlobal$ = this._isGlobal$.asObservable();

    public filters$: Observable<TeamFiltersFills> = this._isGlobal$
        .pipe(
            switchMap((isGlobal) => isGlobal
                ? this._getDefaultFilters()
                : this._teamStore.filterKeys$
            )
        );

    public sortedLocations$ = this.filters$
        .pipe(map(({ locations }) => {
            if (!locations?.length) {
                return [];
            }
            locations = locations
                .filter((e: string) => {
                    if (!e || e?.length <= 2) {
                        return false;
                    }

                    const locationsParts = e
                        .split(',')
                        .filter(e => e);

                    if (locationsParts.length < 2) {
                        return false;
                    }

                    return true;
                })
                .sort((a, b) => {
                    return a.localeCompare(b);
                });

            return Array.from(new Set(locations));
        }));

    public sortedIndustries$ = this.filters$
        .pipe(map(({ industries }) => {
            if (!industries?.length) {
                return [];
            }
            return industries
                .filter((e: Industry) => e)
                .sort((a: Industry, b: Industry) => {
                    return a.name.localeCompare(b.name);
                });
        }));

    public gradYears$ = this.filters$
        .pipe(map(({ grad_years }) => {
            if (!grad_years?.length) {
                return [];
            }
            return grad_years
                .filter((e: string) => e)
                .sort()
                .reverse();
        }));

    public usersTypes$ = this.filters$
        .pipe(map(({ user_types }) => !user_types.length
            ? []
            : user_types
                .map((e) => ({
                    value: e,
                    label: UsersTypesLables[e]
                }))
        ));

    public sports$ = this._isGlobal$
        .pipe(
            switchMap((global) => {
                if (global) {
                    return this._sportsStore.allSports$;
                }

                return this._teamStore.filterKeys$.pipe(map(({sports}) => sports));
            })
        );

    public init(global: boolean) {
        this._isGlobal$.next(global);
    }

    private _getDefaultFilters() {
        return this._http.get<Array<Industry>>('industries/filter')
            .pipe(
                map((industries) => (
                    {
                        grad_years: this._utilities.makeClasses(),
                        locations: LOCATIONS,
                        industries,
                        user_types: Object.values(UserType)
                    }
                )));
    }
}
