import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, filter, switchMap, take, tap } from 'rxjs';
import { DOCUMENT, Location } from '@angular/common';
import { AuthService } from '@athlete-x/features/auth/services/auth.service';
import { UserService } from '@athlete-x/services/user.service';
import { MainLoaderService } from './main-loader.service';
import { TeamService } from './team.service';
import { TeamStore } from '@athlete-x/stores';
import { TeamDetailsService } from '@athlete-x/services/team-details.service';
import { Team } from '@athlete-x/definitions';
import { SportsService } from '@athlete-x/shared';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    private get _currentUrl() {
        const full = this._location.path().split('?')[ 0 ];
        if (this._team.slug) {
            return full.replace('/' + this._team.slug, '');
        }

        return full;
    }

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _auth: AuthService,
        private _router: Router,
        private _user: UserService,
        private _sports: SportsService,
        private _loader: MainLoaderService,
        private _location: Location,
        private _team: TeamService,
        private _teamDetails: TeamDetailsService,
        private _teamStore: TeamStore
    ) {
        const width = window.innerWidth;
        if (width < 600) {
            window.document.body.className = window.document.body.className + ' under-600';
        }

        if (this._isPublicProfile) {
            return;
        }

        this._auth.isAuthenticated$.pipe(
            tap(() => this._hideLoader()),
            filter((authenticated) => !!authenticated),
            switchMap(() => combineLatest([
                this._user.load(),
                this._teamStore.team$,
                this._sports.load()
            ])),
            take(1)
        ).subscribe(([user, team]) => {
            const currentTeam = team as Team;
            const isCurrentTeamMember = currentTeam && user.teams.find((t) => +t.id === +currentTeam.id);
            if (!team) {
                this._redirectToTeam(user.teams[0]);
                // this._router.navigate(['/team-selection']);

            } else if (!user.profile_completed) {
                this._router.navigate(['/profile-complete']);

            } else if (
                (!isCurrentTeamMember || currentTeam.status === 'pending') &&
                !this._currentUrl.includes('/user/')
            ) {
                this._router.navigate(['/join-team']);

            } else if (['', '/', '/auth'].includes(this._currentUrl)) {
                this._router.navigate(['/']);
            }

            this._hideLoader();
        });
    }

    private _hideLoader() {
        // Dont hide loader when on public profile link
        if (this._currentUrl.includes('/u/') === false) {
            this._loader.hide(1000);
        }
    }

    private get _isPublicProfile() {
        return this._currentUrl.includes('/u/');
    }

    private _redirectToTeam(team: Team) {
        const redirect = this._document?.baseURI + team.slug;
        this._document.location.href = redirect;
    }

    public logout() {
        this._loader.show()
            .then(() => {
                this._auth.logout();
                window.location.reload();
            });
    }

}
