export enum UserType {
    ALUMNI = 'Alumni',
    PLAYER = 'Player',
    STAFF = 'Staff',
    PARENT = 'Parent',
    FRIEND = 'Friend'
}

export const UserTypes: Array<{ label: string, value: string }> = [
    {
        label: 'Alumni',
        value: UserType.ALUMNI
    },
    {
        label: 'Current Player',
        value: UserType.PLAYER
    },
    {
        label: 'Staff',
        value: UserType.STAFF
    },
    {
        label: 'Parent',
        value: UserType.PARENT
    },
    {
        label: 'Friend',
        value: UserType.FRIEND
    }
];

export const UsersTypesLables: { [key: string]: string } = {
    'Alumni': 'Alumni',
    'Player': 'Current Player',
    'Staff': 'Staff',
    'Parent': 'Parent',
    'Friend': 'Friend'
};

export type TeamTypes = 'high_school' | 'college' | 'group';

export enum TeamTypesTitle {
    'high_school' = 'High School',
    'college' = 'College',
    'group' = 'Group'
}

export enum TeamType {
    HIGH_SCHOOL = 'high_school',
    COLLEGE = 'college',
    GROUP = 'group'
}

export type Gender = 'male' | 'female';

export type Directory = 'global' | 'private';

export type AutocompleteType =
    'high_school'
    | 'college'
    | 'company'
    | 'interested_company'
    | 'major'
    | 'sport'
    | 'group'
    | 'title'
    | 'mentor'
    | 'mentee';

export type SubscriptionType = 'Month' | 'Year';

export type VisibilitySettings = 'always_show' | 'show_for_teams_only' | 'show_for_school_teams_only' | 'always_hide';

export enum AUTOCOMPLETE_TEXT_MAP {
    'high_school' = 'Enter High School...',
    'college' = 'Enter College...',
    'company' = 'Company Name',
    'interested_company' = 'Interests...',
    'major' = 'Enter Major...',
    'sport' = 'Enter Sport...',
    'group' = 'Group',
    'positions' = 'Add position...',
    'title' = 'Add position...',
    'mentor' = 'Add mentor...',
    'mentee' = 'Add mentee...'
}

export enum AspectRatioTypes {
    SQUARE = 'square',
    RECTANGLE = 'rectangle',
    ORIGINAL = 'orginal',
    WIDE = 'wide'
}

export enum CompresionTypes {
    JPEG = 'jpeg',
    PNG = 'png'
}

export enum ImageSizes {
    BIG = 1200,
    MEDIUM = 800,
    SMALL = 400,
    BANNER = 1920,
    AVATAR = 340,
}

export const TeamGenders: { [key: string]: Array<{ label: string, value: string }> } = {};
TeamGenders[TeamType.COLLEGE] = [
    {
        label: 'Mens',
        value: 'male',
    },
    {
        label: 'Womens',
        value: 'female',
    }
];

TeamGenders[TeamType.HIGH_SCHOOL] = [
    {
        label: 'Boys',
        value: 'male',
    },
    {
        label: 'Girls',
        value: 'female',
    }
];

export interface TeamFiltersFills {
    grad_years: Array<string>,
    industries: Array<Industry>,
    locations: Array<string>,
    user_types: Array<UserType>
    sports?: Array<Sport>,
}

export interface AutocompleteItem {
    id: number | string | any,
    name: string,
    nickname?: string,
    type?: string,
    mentorship_id?: number,
    deleted?: boolean
}

export interface Team {
    active_users_count: number;
    admin: boolean;
    app_package?: string;
    banner_link: string;
    college: College;
    custom_domain?: string;
    disabled: boolean;
    gender: Gender;
    group: Group;
    high_school: HighSchool;
    id: number;
    jersey_number?: number | string;
    position?: string;
    logo_link: string;
    name: string;
    school_name: string;
    /** @deprecated */
    team_links: Array<TeamLink>;
    slug: string;
    sport: Sport;
    status: 'active' | 'pending' | 'rejected';
    user_type: UserType;
    team_category: 'school' | 'group';
    type: string;
    /// Additional data
    slogan?: string;
    color?: string;
    teamType: TeamType;
}

export interface TeamAdminData {
    subscription_configured: boolean;
    subscription_unpaid: boolean;
    payment_plan: 'tiered' | 'free' | '',
    free_tier_limit: number,
    has_active_coupon: any
}

export interface UnregisteredTeam {
    id: number
    high_school: HighSchool,
    college: College,
    sport: Sport,
    group: Group,
    user_type: UserType,
    gender: Gender,
    type: string
}

export interface TeamLink {
    id: string;
    name: string;
    position: number;
    url: string;
}

export interface WaitlistUser {
    avatar: string;
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    full_name: string;
    user_type?: UserType;
    user_teams: Array<TeamInfo>;
    position?: string;
    jersey_number?: number | string;
}

export interface TeamInfo {
    id: number;
    team: Partial<Team>;
    user_type: UserType;
    position?: string;
    jersey_number?: number | string;
}

export interface User {
    id: number;
    first_name: string;
    last_name: string;
    full_name: string;
    address: string;
    avatar: string;
    global: boolean;
    interested_companies: Array<Company>;
    email: string;
    external_id: string;
    college: College | null;
    hometown: string;
    industries: Array<Industry>; //
    information: boolean;
    internship: boolean;
    job: boolean;
    links: any; //
    major: Major | null;
    mentorship: boolean;
    notes: string;
    phone: string;
    position: string;
    profile_completed: boolean;
    resume: string;
    school_class: string;
    teams: Array<Team>;
    team_id: number;
    username: string; // not used?
    sports: Array<Sport>;
    groups: Array<Group>;
    user_colleges: Array<UserCollege>;
    user_high_schools: Array<UserHighSchool>;

    unregistered_teams: Array<UnregisteredTeam>;
    twitter: string;
    instagram: string;
    tiktok: string;
    snapchat: string;
    linkedin: string;
    website: string;
    user_teams: Array<TeamInfo>;
    user_type?: UserType;
    phone_visibility_setting: VisibilitySettings;
    email_visibility_setting: VisibilitySettings;
    grad_year_visibility_setting: VisibilitySettings;
    privacy_only_my_city: boolean;
    privacy_only_my_industry: boolean;
    privacy_only_my_college: boolean;
    privacy_only_my_sport: boolean;
    previous_work: any;
    allow_public_access: boolean;
    video_link: string;

    looking_for_information: boolean;
    looking_for_internship: boolean;
    looking_for_job: boolean;
    looking_for_mentorship: boolean;
    looking_for_networking: boolean;

    can_help_mentorship: boolean;
    can_help_networking: boolean;
    can_help_information: boolean;
    can_help_internship: boolean;
    can_help_job: boolean;

    industry_focus: string;

    work_positions: Array<WorkPosition>;

    mentorships_as_mentor: Array<UserMentorship>;
    mentorships_as_mentee: Array<UserMentorship>;
}

export interface WorkPosition {
    id: number;
    company_id: number,
    company_name: string,
    title: string,
    currently_active: boolean,
    start_date: string,
    end_date: string,
    deleted?: boolean,
    type?: string
}

export interface UnregisteredTeam {
    college: College;
    gender: Gender;
    group: Group;
    high_school: HighSchool;
    id: number;
    sport: Sport;
    user: User;
    user_type: UserType;
}

export interface DirectoryUser extends User {
    status?: 'active' | 'pending' | 'rejected';
}

export interface Message {
    attachment_link: Array<string>;
    author: User;
    created: 'string';
    id: number;
    links: any;
    message: string;
    target_group: UserType;
    type: 'user';
}

export interface Sport {
    id: number;
    name: string;
    type?: 'sport';
}

export interface Group {
    id: number;
    name: string;
    type?: 'group';
}

export interface Pagination {
    totalCount: number;
    totalPages: number;
    pageItems: number;
}

export interface Industry {
    id: number;
    name: string;
    type?: 'industry' | 'company';
}

export interface Job {
    title: string;
    type: string;
}

export interface Company {
    id: number;
    name: string;
    type?: 'company';
}

export interface UserMentorship {
    id?: number,
    mentee_id: number,
    mentee_name?: string,
    mentor_id: number,
    mentor_name?: string,
    deleted?: boolean,
    type?: 'mentorship'
}

export interface HighSchool {
    id: number;
    name: string;
    nickname: string;
    city: string;
    state: string;
    type?: 'high_school';
    deleted: boolean;
}

export interface College {
    id: number;
    name: string;
    nickname: string;
    city: string;
    state: string;
    type?: 'college';
    deleted: boolean;
}

export interface UserHighSchool {
    id: number;
    grad_year: string;
    high_school: HighSchool;
    type?: 'user_high_school'
}

export interface UserCollege {
    id: number;
    grad_year: string;
    college: College;
    majors: Array<Major>;
    type?: 'user_college'
}

export interface Conglomerate {
    id: number;
    name: string;
    teams?: Array<HighSchool | College>;
    type?: 'conglomerate';
}

export interface Major {
    id: number;
    name: string;
    type: 'major';
}

export interface AutocompletedPlace {
    id: number,
    city?: string;
    state?: string;
    zip_code?: string;
    country?: string;
    street_number?: string;
    street?: string;
    address_query?: string;
}

export interface Position {
    abbreviation: string;
    id?: number;
    links?: string;
    name?: string;
    sport?: Sport;
    type?: string;
    user_type?: UserType;
}

export interface Pagination {
    pageItems: number,
    totalCount: number,
    totalPages: number
}

export interface Customer {
    address_city: string;
    address_country: string
    address_line1: string
    address_line2: string;
    address_postal_code: string;
    address_state: string;
    email: string;
    id: string;
    name: string;
    phone: string;
}

export type SubscriptionStatus = 'pending' | 'awaiting_confirmation' | 'active' | 'closed' | 'expired';
export const BlockingSubscriptionStatuses: Array<SubscriptionStatus> = [
    'active',
    'pending',
    'awaiting_confirmation'
];

export const SubscriptionStatusLabels: Record<SubscriptionStatus, string> = {
    'pending': 'Pending',
    'awaiting_confirmation': 'Awaiting confirmation',
    'active': 'Active',
    'closed': 'Closed',
    'expired': 'Expired'
};

export const SubscriptionStatusTexts: Record<SubscriptionStatus, string> = {
    'pending': 'Product configured, payment options available',
    'awaiting_confirmation': 'Awaiting confirmation from payment gate',
    'active': 'Active subscription, click to manage',
    'closed': 'Closed subscription',
    'expired': 'Expired'
};

export interface Subscription {
    id: string,
    general_status: SubscriptionStatus,
    payment_status: string,
    stripe_session_url?: string;
    customer: Customer;
    user: User,
}

export interface Price {
    amount: number,
    id: number,
    interval_string: string,
    price_group_id: number,
    type: string
}

export const SocialUrlsMap = {
    twitter: 'https://twitter.com/',
    instagram: 'https://instagram.com/',
    tiktok: 'https://tiktok.com/',
    snapchat: 'https://snapchat.com/add/',
    linkedin: 'https://linkedin.com/in/'
};

export type Socials = keyof typeof SocialUrlsMap;
export type Contacts = 'email' | 'phone' | 'website' | 'strya';

export interface ContactItem {
    icon: string;
    type: Socials | Contacts;
    label: string;
    link: string;
}

export const ContactsIconsNames: Record<Socials | Contacts, string> = {
    email: 'mail-outline',
    phone: 'call-outline',
    linkedin: 'logo-linkedin',
    instagram: 'logo-instagram',
    website: 'globe-outline',
    twitter: 'logo-twitter',
    tiktok: 'logo-tiktok',
    snapchat: 'logo-snapchat',
    strya: 'apps-outline',
};

export const ContactsOrder: Array<Socials | Contacts> = [
    'email',
    'phone',
    'linkedin',
    'instagram',
    'website',
    'twitter',
    'tiktok',
    'snapchat',
    'strya',
];

export interface WorkPositionParams {
    id?: number | null,
    company_id?: number | null,
    company_name?: string | null,
    title?: string | null,
    currently_active?: boolean | null,
    start_date?: string | null,
    end_date?: string | null,
    deleted?: boolean | null,
    type?: string
}


