// eslint-disable-next-line @nx/enforce-module-boundaries
import { IsAuthenticated } from '@athlete-x/features/auth/services/is-authenticated.guard';
import { Routes } from '@angular/router';
import { AuthenticatedComponent } from './containers/authenticated/authenticated.component';
import { isGeneralDomain } from '@athlete-x/shared/classes/DomainHelper';

const withTeamRoutes: Routes = [
    {
        path: 'auth',
        loadChildren: () =>
            import('@athlete-x/features/auth/auth.module').then(
                (m) => m.AuthModule
            ),
    },
    {
        path: 'profile-complete',
        canActivate: [IsAuthenticated],
        loadChildren: () =>
            import(
                './modules/profile-complete/profile-complete.module'
                ).then((m) => m.ProfileCompleteModule),
    },
    {
        path: '',
        component: AuthenticatedComponent,
        canActivate: [IsAuthenticated],
        children: [
            {
                path: 'join-team',
                loadChildren: () =>
                    import(
                        './modules/join-team/join-team-page.module'
                    ).then((m) => m.JoinTeamPageModule),
            },
            {
                path: 'edit-profile',
                loadChildren: () =>
                    import('./modules/edit-profile/edit-profile.module').then(
                        (m) => m.EditProfileModule
                    ),
            },
            {
                path: 'payment',
                loadChildren: () =>
                    import('./modules/payment/payment.module').then(
                        (m) => m.PaymentModule
                    )
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/directory/directory-page.module').then(
                        (m) => m.DirectoryPageModule
                    ),
            },
            {
                path: 'waitlist',
                loadChildren: () =>
                    import('@athlete-x/features/waitlist/waitlist.module').then(
                        (m) => m.WaitlistModule
                    ),
            },
            {
                path: 'team-links',
                loadChildren: () =>
                    import('./modules/team-links/team-links.module').then(
                        (m) => m.TeamLinksModule
                    ),
            },
            {
                path: 'teams-list',
                loadChildren: () =>
                    import('./modules/teams-list/teams-list.module').then(
                        (m) => m.TeamsListPageModule
                    ),
            },
            {
                path: 'message-board',
                loadChildren: () =>
                    import(
                        './modules/message-board/message-board-page.module'
                    ).then((m) => m.MessageBoardPageModule),
            },
            {
                path: 'user',
                loadChildren: () =>
                    import('./modules/profile-page/profile-page.module').then(
                        (m) => m.ProfilePageModule
                    ),
            },
        ],
    },
    {
        path: '**',
        redirectTo: '/',
        pathMatch: 'full',
    },
];

const noTeamRoutes = [
    // {
    //     path: '',
    //     loadChildren: () =>
    //         import('./landing/landing.module')
    //             .then((m) => m.LandingPageModule),
    // },
    {
        path: '',
        loadChildren: () =>
            import('@athlete-x/features/auth/auth.module').then(
                (m) => m.AuthModule
            ),
    },
    {
        path: 'u/:externalId',
        loadChildren: () =>
            import('./modules/public-profile-page/public-profile.module').then(
                (m) => m.PublicProfileModule
            ),
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('@athlete-x/features/auth/auth.module').then(
                (m) => m.AuthModule
            ),
    },
    {
        path: 'team-selection',
        loadChildren: () =>
            import('./modules/team-selection-page/team-selection.module').then(
                (m) => m.TeamSelectionModule
            ),
        canActivate: [IsAuthenticated],
    },
    {
        path: '**',
        redirectTo: '',
    },
];

const hasPotentialTeamSlug = () => {
    const basePath = globalThis.TEAM_SLUG;
    const routerPaths = [...withTeamRoutes, ...noTeamRoutes]
        .map((r) => r.path);

    if (!basePath || routerPaths.includes(basePath)) {
        return false;
    }

    // parametrized public profile page
    if (basePath === 'u') {
        return false;
    }

    return true;
};

const getRoutes = () => {
    if (isGeneralDomain() && hasPotentialTeamSlug() === false) {
        return noTeamRoutes;
    }

    return withTeamRoutes;
};

export { getRoutes, hasPotentialTeamSlug };
