import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SportsStore } from '@athlete-x/stores';
import { Observable, switchMap, take, tap } from 'rxjs';
import { Sport, AUTOCOMPLETE_ENDPOINTS } from '@athlete-x/definitions';

@Injectable({
    providedIn: 'root'
})
export class SportsService {
    constructor(
        private _http: HttpClient,
        private _sports: SportsStore,
    ) {
    }

    load(): Observable<Array<Sport>> {
        return this._sports.isLoaded$
            .pipe(
                take(1),
                switchMap((sports) => {
                    if (!sports) {
                        return this._load();
                    }

                    return this._sports.allSports$
                        .pipe(take(1));
                })
            );
    }

    _load() {
        return this._http.get<Array<Sport>>(AUTOCOMPLETE_ENDPOINTS.sport)
            .pipe(
                tap((sports) => {
                    this._sports.setSports(sports);
                })
            );
    }

}
