<ion-list class="sidebar-menu-item message-board athletex-theme" lines="none">

    <ion-list-header class="ion-no-padding">
        <div class="header-container">
            <span>Posts</span>

            <ion-buttons>

                <ion-button (click)="postNewMessage()" title="Create New" class="side-menu-button">
                    <ion-icon name="add-outline" style="font-size: 1.3rem;"></ion-icon>
                </ion-button>

                <ion-button [routerLink]="['/message-board']" title="View All" class="side-menu-button">
                    <ion-icon name="reader-outline" style="font-size: 1rem;"></ion-icon>
                </ion-button>

            </ion-buttons>

        </div>
    </ion-list-header>
    <ng-container *ngIf="items$ | async as items">
        <ion-item class="ion-no-padding "
            *ngFor="let message of items; let idx = index; trackBy: getId">
                <ion-grid class="post-container ion-no-padding"
                    [routerLink]="['/message-board']">
                    <ion-row>
                        <ion-col size="2">
                            <atx-user-avatar
                                [avatar]="message.author.avatar">
                            </atx-user-avatar>
                        </ion-col>
                        <ion-col size="10" class="message-container">
                                <div class="header">
                                    <span class="title">{{ message.author.full_name }}</span>
                                    <span class="timestamp">{{ message.created | timestampParse }}</span>
                                </div>
                                <div class="message">
                                    <span class="text"> {{ message.message }} </span>
                                    <span class="view-overlay">VIEW</span>
                                </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>
        </ion-item>
    </ng-container>
</ion-list>
