import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    BehaviorSubject,
    map,
    tap,
    of,
    withLatestFrom,
    take,
    switchMap,
    from,
    exhaustMap,
    delay,
    finalize, toArray, concatMap, Observable, catchError
} from 'rxjs';
import { ENDPOINTS, Message, Pagination } from '@athlete-x/definitions';
import { TeamStore } from '@athlete-x/stores';
import { ModalController } from '@ionic/angular';
import { PostMessageModalComponent } from '../components/post-message-modal/post-message-modal.component';
import { UtilitiesService } from '@athlete-x/shared';

const FIRST_PAGE = 1;
const DATA_PER_PAGE = 8;

@Injectable({
    providedIn: 'root'
})
export class MessageBoardService {
    private _endReached$ = new BehaviorSubject(false);
    public endReached$ = this._endReached$.asObservable();

    private _data$ = new BehaviorSubject<Array<Message>>([]);
    public data$ = this._data$.asObservable();
    public count$ = this._data$.asObservable()
        .pipe(map((data) => data.length));

    private _processing$ = new BehaviorSubject(true);
    public processing$ = this._processing$.asObservable();

    private _page: number = FIRST_PAGE;

    constructor(
        private _http: HttpClient,
        private _team: TeamStore,
        private _modalCtrl: ModalController,
        private _utlities: UtilitiesService
    ) {
    }

    private _load(page: number, perPage = DATA_PER_PAGE) {
        const body = {
            team_id: this._team.id,
            per_page: perPage,
            page
        };

        this._startProcessing();

        return this._http
            .post<{ data: Array<Message>, pagination: Pagination }>(ENDPOINTS.getPosts, body )
            .pipe(
                map((response) => {
                    const { data, pagination } = response;
                    if (page === +pagination.totalPages) {
                        this._endReached$.next(true);
                    }

                    return data;
                }),
                withLatestFrom(this.data$),
                tap(([Data, oldData]) => {
                    if (page > FIRST_PAGE) {
                        Data = [
                            ...oldData,
                            ...Data
                        ];
                    }

                    this._endProcessing();
                    this._data$.next(Data);
                })
            );
    }

    public load() {
        this._page = FIRST_PAGE;
        return this._load(this._page);
    }

    public loadFirstN(perPage: number) {
        this._page = FIRST_PAGE;
        return this._load(this._page, perPage);
    }

    public loadMore() {
        this.endReached$
            .pipe(
                take(1),
                switchMap((endReached) => {
                    if (endReached) {
                        return of(null);
                    }

                    this._page += 1;
                    return this._load(this._page);
                })
            )
            .subscribe();
    }

    public async postNewMessage() {
        return this._modalCtrl
            .create({
                component: PostMessageModalComponent
            })
            .then((modal) => {
                modal.present();
                return modal.onWillDismiss();
            })
            .then((onDismissData) => {
                const { data } = onDismissData;

                if (data) {
                    this.createPost(data).subscribe();
                }
            });
    }

    public createPost(params: { post: any, attachments: any }) {
        const {
            post,
            attachments
        } = params;


        this._startProcessing();

        return this._http
            .post(ENDPOINTS.createPost, {
                ...post,
                team_id: this._team.id
            })
            .pipe(
                switchMap((post: any) => {
                    const { id } = post;

                    if (id && attachments && attachments.length) {
                        return this._uploadAttachments(id, attachments);

                    } else {
                        return of(null);

                    }
                }),
                switchMap(() => this.load()),
                catchError((err) => {
                    alert('We have a problem, try again later.');
                    this._endProcessing();

                    return err;
                })
            );
    }

    private _uploadAttachments(postId: number, attachments: Array<{ filename: string, file: File }>) {
        return from(attachments)
            .pipe(
                concatMap((attachment) => {
                    return this._fileToBase64(attachment.file)
                        .pipe(
                            switchMap((base64) => this._http
                                .patch(ENDPOINTS.uploadAttachment, {
                                    post_id: postId,
                                    attachments: [{
                                        attachment_base64: base64,
                                        filename: attachment.filename
                                    }]
                                }))
                        );
                }),
                toArray()
            );
    }

    private _startProcessing() {
        if (!this._processing$.value) {
            this._processing$.next(true);
        }
    }

    private _endProcessing() {
        if (this._processing$.value) {
            setTimeout(() => {
                this._processing$.next(false);
            }, 500);
        }
    }

    private _fileToBase64(file: File) {
        const myReader: FileReader = new FileReader();
        myReader.readAsDataURL(file);

        return new Observable((obs) => {
            myReader.onloadend = () => {
                const dataUrl = myReader.result as string;
                const base64 = dataUrl.split('base64,')[1];
                obs.next(base64);
                obs.complete();
            };
        });
    }

    public removePost(id: number) {
        const alertContent = {
            header: 'Post remove',
            subHeader: '',
            message: 'Are you sure that you want to remove this post?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'OK',
                    role: 'confirm',
                }
            ]
        };

        this._utlities.presentAlert(alertContent)
            .then(response => {
                const { role } = response;
                if (role === 'confirm') {
                    return this._http
                        .post(ENDPOINTS.deletePost, { id })
                        .subscribe({
                            next: () => this._removeItemFromPosts(id)
                        });
                } else {
                    return null;
                }
            });
    }

    private _removeItemFromPosts(itemId: number) {
        const data = this._data$.value.filter(post => post.id !== itemId);

        this._data$.next(data);
    }

}
