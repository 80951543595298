import { Injectable } from '@angular/core';
import { map, ReplaySubject, startWith } from 'rxjs';
import { Sport } from '@athlete-x/definitions';

@Injectable({
    providedIn: 'root'
})
export class SportsStore {
    private _sports!: Array<Sport>;
    private _sports$ = new ReplaySubject<Array<Sport>>(1);

    public allSports$ = this._sports$.asObservable();
    public isLoaded$ = this.allSports$.pipe(
        startWith(false),
        map((value) => !!value)
    );

    public setSports(sports: Array<Sport>) {
        this._sports = sports;
        // console.log('Sports data', sports);

        this._sports$.next(this._sports);
    }
}
